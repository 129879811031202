import React from "react";
import Layout from "../../components/Layout";
import { graphql } from "gatsby";
import DynamicZone from "../../components/DynamicZone";
import { SEO } from "../../components/Seo";
import { motion } from "framer-motion";

const Page = ({ location, data }) => {
  const PAGE = data.strapiAppPage;
  const titleVariants = {
    initial: {
      y: 0,
    },
    enter: {
      y: "-100%",
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <Layout location={location}>
      <div className="container mx-auto px-4">
        <div className="relative overflow-hidden pt-40 pb-32">
          <motion.h1 className="font-serif text-5xl font-bold mix-blend-difference md:text-9xl">
            {PAGE.titre}
          </motion.h1>
          <motion.div
            className="absolute top-0 left-0 h-full w-full bg-brown-500"
            initial={"initial"}
            animate={"enter"}
            variants={titleVariants}
          />
        </div>
      </div>
      {PAGE.blocks && <DynamicZone blocks={PAGE.blocks} />}
    </Layout>
  );
};

Page.propTypes = {};

export const query = graphql`
  query HomePage($slug: String) {
    strapiAppPage(slug: { eq: $slug }) {
      slug
      id
      titre
      locale
      blocks {
        __typename
        ...STRAPI__COMPONENT_PAGE_DZ_GALLERIE
        ...STRAPI__COMPONENT_APP_ILLUSTRATED_TEXT
        ...STRAPI__COMPONENT_PAGE_DZ_TEXTE_ENRICHI
        ...STRAPI__COMPONENT_APP_FEATURES_CAROUSEL
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          localFile {
            publicURL
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
            }
          }
        }
        metaSocial {
          title
          description
          socialNetwork
        }
      }
    }
  }
`;

export const Head = ({ location, params, data, pageContext }) => {
  const PAGE = data.strapiAppPage;

  return (
    <SEO
      title={PAGE.titre}
      image={PAGE.seo?.metaImage?.localFile?.childImageSharp?.resize?.src || null}
      description={PAGE.seo?.metaDescription || null}
    />
  );
};

export default Page;
